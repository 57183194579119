import Icons from "../../assets/sprite.svg";
import { IconType } from "./utils/types";
import "./styles/Icon.module.css";

interface IconProps {
  name: IconType;
  hidden4Sr?: boolean;
  accessible_name?: string;
}

export const Icon = ({
  name,
  hidden4Sr = true,
  accessible_name = "",
}: IconProps) => {
  return (
    <svg
      viewBox="0 0 24 24"
      {...(hidden4Sr ? { "aria-hidden": "true" } : { role: "img" })}
    >
      {!hidden4Sr && <title>{accessible_name}</title>}
      <use href={`${Icons}#${name}`} />
    </svg>
  );
};
