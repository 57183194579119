import { Link, useLocation } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import { Icon } from "../Icon";
import { IconType } from "../Icon/utils/types";
import logo from "../../assets/lera.png";
import styles from "./Sidebar.module.css";

export const Sidebar = () => {
  const location = useLocation();

  return (
    <div className={styles.sidebar_container}>
      <div className={styles.logo}>
        <Link to="/" className={styles.logo_link}>
          <img src={logo} alt="Lera API Dashboard" />
        </Link>
      </div>
      <nav aria-label="Main" className={styles.main_nav}>
        <ul>
          {SidebarData.map((item, index) => (
            <li key={index}>
              <Link
                to={item.path[0]}
                {...(item.path.includes(location.pathname) && {
                  "aria-current": "page",
                })}
              >
                <Icon name={item.title.replaceAll(" ", "") as IconType} />
                <span>{item.title}</span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};
