import { Link } from "react-router-dom";
import React, { useId, useState } from "react";
import styles from "./styles/expiredverification.module.css";
import logo from "../../assets/lera.png";
import { PageTitle } from "../../components/PageTitle";
import { SubmitButton } from "../../components/SubmitButton";
import { TextField } from "../../components/TextField";

export const ExpiredActivationLink = () => {
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState({ email: "" });
  const fieldIds = { email: useId() };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setFormErrors({ email: "" });
  };

  return (
    <PageTitle title="Resend Activation Email - Lera API">
      <div className={styles.page_container}>
        <header className={styles.header}>
          <Link to="/">
            <img src={logo} alt="Lera API" className={styles.logo} />
          </Link>
        </header>
        <main className={styles.activate_container}>
          <h1 className={styles.activate_text}>Expired Verification Link</h1>
          <div className={styles.activate_box}>
            <p className={styles.message}>
              The verification link has expired. Please enter you email address to receive a new verification link.
            </p>
            <form className={styles.form_container} noValidate>
              <TextField
                id={fieldIds.email}
                label="Email Address"
                type="email"
                name="email"
                value={email}
                setValue={handleEmailChange}
                errMsg={formErrors.email}
                stylesClass={styles.input_box}
              />
              <div className={styles.btn_container}>
                <div className={styles.button_wrapper}>
                  <SubmitButton />
                </div>
              </div>
            </form>
          </div>
        </main>
      </div>
    </PageTitle>
  );
};
