// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthLayout_skip__i1gii {
  text-decoration: none;
  position: absolute;
  z-index: 3;
  transform: translateY(-200%);
  transition: transform 0.3s ease-in-out;
}

.AuthLayout_skip__i1gii:focus-visible {
  transform: translateY(0);
}

.AuthLayout_skip__i1gii,
.AuthLayout_skip__i1gii:focus-visible {
  padding: 0.5rem 1rem;
}

.AuthLayout_auth_layout__Qgwoy {
  background-color: var(--backgroundPrimary);
  padding: 1.5rem 5% 1.5rem 0;
  min-height: 100vh;
}

.AuthLayout_auth_layout__Qgwoy header,
.AuthLayout_auth_layout__Qgwoy main {
  display: contents;
}

.AuthLayout_auth_page__k2Oru {
  display: grid;
  grid-template: auto 1fr / auto auto 1fr;
}

.AuthLayout_sidebar__yIVwJ {
  grid-area: 1 / 1 / -1;
  margin: -1.5rem 2rem -1.5rem 0; /* -1.5rem to override padding from global container */
  background-color: var(--backgroundSecondary);
}

.AuthLayout_profile_link__WxjgY {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  grid-area: 1 / 3;
  justify-self: end;
  text-decoration: none;
  line-height: 1;
  border-radius: 50%;
  background: var(--secondary);
}

.AuthLayout_profile_link__WxjgY,
.AuthLayout_profile_link__WxjgY:hover {
  color: var(--contentActive);
}

.AuthLayout_profile_link__WxjgY,
.AuthLayout_profile_link__WxjgY:focus-visible {
  padding: 0.5rem;
}

.AuthLayout_page_heading__08m01 {
  grid-area: 1 / 2;
  justify-self: start;
  font-size: 1.5rem;
}

.AuthLayout_content_container__jKQc4 {
  grid-area: 2 / 2 / -1 / -1;
  margin-top: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/AuthLayout/styles/AuthLayout.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,UAAU;EACV,4BAA4B;EAC5B,sCAAsC;AACxC;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;;EAEE,oBAAoB;AACtB;;AAEA;EACE,0CAA0C;EAC1C,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uCAAuC;AACzC;;AAEA;EACE,qBAAqB;EACrB,8BAA8B,EAAE,sDAAsD;EACtF,4CAA4C;AAC9C;;AAEA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,qBAAqB;EACrB,cAAc;EACd,kBAAkB;EAClB,4BAA4B;AAC9B;;AAEA;;EAEE,2BAA2B;AAC7B;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;EAC1B,kBAAkB;AACpB","sourcesContent":[".skip {\n  text-decoration: none;\n  position: absolute;\n  z-index: 3;\n  transform: translateY(-200%);\n  transition: transform 0.3s ease-in-out;\n}\n\n.skip:focus-visible {\n  transform: translateY(0);\n}\n\n.skip,\n.skip:focus-visible {\n  padding: 0.5rem 1rem;\n}\n\n.auth_layout {\n  background-color: var(--backgroundPrimary);\n  padding: 1.5rem 5% 1.5rem 0;\n  min-height: 100vh;\n}\n\n.auth_layout header,\n.auth_layout main {\n  display: contents;\n}\n\n.auth_page {\n  display: grid;\n  grid-template: auto 1fr / auto auto 1fr;\n}\n\n.sidebar {\n  grid-area: 1 / 1 / -1;\n  margin: -1.5rem 2rem -1.5rem 0; /* -1.5rem to override padding from global container */\n  background-color: var(--backgroundSecondary);\n}\n\n.profile_link {\n  font-family: \"DM Sans\", sans-serif;\n  font-weight: 700;\n  grid-area: 1 / 3;\n  justify-self: end;\n  text-decoration: none;\n  line-height: 1;\n  border-radius: 50%;\n  background: var(--secondary);\n}\n\n.profile_link,\n.profile_link:hover {\n  color: var(--contentActive);\n}\n\n.profile_link,\n.profile_link:focus-visible {\n  padding: 0.5rem;\n}\n\n.page_heading {\n  grid-area: 1 / 2;\n  justify-self: start;\n  font-size: 1.5rem;\n}\n\n.content_container {\n  grid-area: 2 / 2 / -1 / -1;\n  margin-top: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skip": `AuthLayout_skip__i1gii`,
	"auth_layout": `AuthLayout_auth_layout__Qgwoy`,
	"auth_page": `AuthLayout_auth_page__k2Oru`,
	"sidebar": `AuthLayout_sidebar__yIVwJ`,
	"profile_link": `AuthLayout_profile_link__WxjgY`,
	"page_heading": `AuthLayout_page_heading__08m01`,
	"content_container": `AuthLayout_content_container__jKQc4`
};
export default ___CSS_LOADER_EXPORT___;
