// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

dialog {
  position: fixed;
  width: min(40ch, 100% - 3rem);
  min-height: 60vh;
  max-height: 75vh;
  border: none;
  border-radius: 0.625rem;
  background-color: var(--backgroundSecondary);
  margin: auto;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-align: center;
}

dialog h2 {
  font-weight: 500;
  font-size: 1.5rem;
}

dialog p {
  font-weight: 600;
  font-size: 1rem;
  color: var(--error);
}

dialog button:first-of-type {
  align-self: flex-end;
}

dialog button:last-of-type {
  align-self: center;
  margin: auto 0 2rem;
  width: max-content;
  padding: 0.5rem 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/ErrorModal/styles/ErrorModal.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,eAAe;EACf,6BAA6B;EAC7B,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;EACZ,uBAAuB;EACvB,4CAA4C;EAC5C,YAAY;EACZ,oBAAoB;EACpB,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,oBAAoB;AACtB","sourcesContent":["dialog::backdrop {\n  background-color: rgba(0, 0, 0, 0.5);\n}\n\ndialog {\n  position: fixed;\n  width: min(40ch, 100% - 3rem);\n  min-height: 60vh;\n  max-height: 75vh;\n  border: none;\n  border-radius: 0.625rem;\n  background-color: var(--backgroundSecondary);\n  margin: auto;\n  padding: 1rem 1.5rem;\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n  text-align: center;\n}\n\ndialog h2 {\n  font-weight: 500;\n  font-size: 1.5rem;\n}\n\ndialog p {\n  font-weight: 600;\n  font-size: 1rem;\n  color: var(--error);\n}\n\ndialog button:first-of-type {\n  align-self: flex-end;\n}\n\ndialog button:last-of-type {\n  align-self: center;\n  margin: auto 0 2rem;\n  width: max-content;\n  padding: 0.5rem 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
