import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import styles from "../../stylesheets/ForgotPassword.module.css";
import logo from "../../assets/lera.png";
import { Link, useNavigate } from "react-router-dom";
import { FORGOT_PASSWORD_ENDPOINT } from "../../api/endpoints";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorMessages from "../../components/ErrorMessages/errorMessage";
import { PageTitle } from "../../components/PageTitle";

export const ForgotPasswordPage = () => {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [formErrors, setFormErrors] = useState<any>({ email: "" });
  const [fieldIds, setFieldIds] = useState<any>({ email: "u-email" });
  const errorsRef = useRef<HTMLDivElement>(null);
  //    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isServerErrorOpen, setIsServerErrorOpen] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string | undefined>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorIsOpen, setErrorIsOpen] = useState<boolean>(false);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [successIsOpen, setSuccessIsOpen] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isValidated, setIsValidated] = useState<boolean>(false);

  const validate = () => {
    setErrorCount(0);
    let errors = { email: "" };
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let count = 0;

    if (!email) {
      errors.email = "You must enter a valid email address";
      count++;
    } else if (!regex.test(email)) {
      errors.email = "You must enter a valid email address";
      count++;
    }
    setErrorCount(count);
    setIsLoading(false);
    setIsValidated(true);
    return errors;
  };

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setIsValidated(false);
    console.log("submit button clicked");
    setFormErrors(validate());
  };

  const errorHandleOpen = () => {
    setErrorIsOpen(true);
  };
  const successHandleOpen = () => {
    setSuccessIsOpen(true);
  };
  const handleClose = () => {
    setErrorIsOpen(false);
    setSuccessIsOpen(false);
    //        setIsSubmitting(false);
    //        setIsServerErrorOpen(false);
  };

  useEffect(() => {
    if (errorCount === 0 && isValidated === true) {
      setIsLoading(true);
      submit();
      console.log("submitting");
    }
    if (errorCount > 0 && errorsRef.current) errorsRef.current.focus();
    //            console.log("submitting")
  }, [formErrors]);

  const submit = async () => {
    try {
      const userData = email;
      const res = await axios.post(FORGOT_PASSWORD_ENDPOINT, userData);
      if (res.status === 200) {
        setIsLoading(false);
        setSuccessMessage("Please check email for reset password link");
        successHandleOpen();
        setTimeout(() => {
          navigate("/login");
        }, 5000);
      }
    } catch (error: any) {
      //            handleOpen();
      setIsLoading(false);
      if (error.response) setServerError(error.response.data.Error);
      else
        setServerError(
          "Unable to process request. Please check your internet connection."
        );
      errorHandleOpen();
    }
  };

  return (
    <PageTitle title="Forgot Password - Lera API">
      <div className={styles.login_container} role="main">
        <img src={logo} alt="Lera logo" className={styles.logo} />
        <div className={styles.login_text} role="heading" aria-level={1}>
          Forgot password
        </div>
        <div className={styles.login_box}>
          <span className={styles.message}>
            Please enter your email address to receive a password reset link
          </span>
          {errorCount !== 0 && (
            <ErrorMessages
              messages={formErrors}
              ids={fieldIds}
              count={errorCount}
              ref={errorsRef}
            />
          )}

          <form
            className={styles.form_container}
            onSubmit={(e) => {
              handleSubmit(e);
            }}
            id="submitForm"
            noValidate
          >
            <div className={styles.input_name}>
              <label htmlFor={fieldIds.email}>Email Address</label>
              <input
                id={fieldIds.email}
                type="email"
                name="email"
                value={email ? email : ""}
                className={styles.input_field}
                style={formErrors.email ? { border: "1px solid #A12F2B" } : {}}
                onChange={(e) => setEmail(e.target.value)}
                aria-describedby="msg-err-email"
              />
              {formErrors.email && (
                <span className={styles.error} id="msg-err-email">
                  {formErrors.email}
                </span>
              )}
            </div>
            <div className={styles.btn_container}>
              <button
                type="submit"
                className={styles.signin_button}
                id="submitBtn"
                disabled={isLoading}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </PageTitle>
  );
};
