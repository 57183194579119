// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KeyDetails_breadcrumb__IG7cg {
  margin: -1.5rem 0 1.25rem;
}

.KeyDetails_breadcrumb__IG7cg a {
  color: inherit;
}

.KeyDetails_details_container__IFKjJ {
  display: flex;
  flex-direction: column;
  background: var(--backgroundSecondary);
  border-radius: 0.875rem;
  padding: 1rem 0.5rem;
}

.KeyDetails_heading__k\\+UFI {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.KeyDetails_heading__k\\+UFI h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

.KeyDetails_details__SVFo\\+ {
  margin-right: 20%;
  display: flex;
  gap: 1.5rem;
}

.KeyDetails_key_details__41X2e,
.KeyDetails_plan_details__bJRuG {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  font-weight: 500;
}

.KeyDetails_topup_link__7sIZJ {
  text-decoration: none;
}

.KeyDetails_topup_link__7sIZJ,
.KeyDetails_topup_link__7sIZJ:focus-visible {
  padding: 0.5rem 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/key-details/styles/KeyDetails.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,sCAAsC;EACtC,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,WAAW;AACb;;AAEA;;EAEE,SAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;;EAEE,oBAAoB;AACtB","sourcesContent":[".breadcrumb {\n  margin: -1.5rem 0 1.25rem;\n}\n\n.breadcrumb a {\n  color: inherit;\n}\n\n.details_container {\n  display: flex;\n  flex-direction: column;\n  background: var(--backgroundSecondary);\n  border-radius: 0.875rem;\n  padding: 1rem 0.5rem;\n}\n\n.heading {\n  margin-bottom: 1.5rem;\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n}\n\n.heading h2 {\n  font-size: 1.5rem;\n  font-weight: 600;\n}\n\n.details {\n  margin-right: 20%;\n  display: flex;\n  gap: 1.5rem;\n}\n\n.key_details,\n.plan_details {\n  flex: 1 1 0px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 1rem;\n  font-weight: 500;\n}\n\n.topup_link {\n  text-decoration: none;\n}\n\n.topup_link,\n.topup_link:focus-visible {\n  padding: 0.5rem 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": `KeyDetails_breadcrumb__IG7cg`,
	"details_container": `KeyDetails_details_container__IFKjJ`,
	"heading": `KeyDetails_heading__k+UFI`,
	"details": `KeyDetails_details__SVFo+`,
	"key_details": `KeyDetails_key_details__41X2e`,
	"plan_details": `KeyDetails_plan_details__bJRuG`,
	"topup_link": `KeyDetails_topup_link__7sIZJ`
};
export default ___CSS_LOADER_EXPORT___;
