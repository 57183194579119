// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubmitButton_submit_btn__jNovg {
  --icon-size: 1rem;
  gap: 0.25rem;
  width: 100%;
  margin-top: 0.4rem;
  padding: 0.625rem;
  font-size: var(--icon-size);
}
`, "",{"version":3,"sources":["webpack://./src/components/SubmitButton/styles/SubmitButton.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,2BAA2B;AAC7B","sourcesContent":[".submit_btn {\n  --icon-size: 1rem;\n  gap: 0.25rem;\n  width: 100%;\n  margin-top: 0.4rem;\n  padding: 0.625rem;\n  font-size: var(--icon-size);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submit_btn": `SubmitButton_submit_btn__jNovg`
};
export default ___CSS_LOADER_EXPORT___;
