// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[aria-label="Breadcrumb"] ol {
  --gap: 0.4rem;
  list-style-type: none;
  display: flex;
  gap: var(--gap);
}

[aria-label="Breadcrumb"] li {
  --icon-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: var(--gap);
}

[aria-label="Breadcrumb"] a {
  text-decoration: none;
  font-size: 1rem;
  font-weight: inherit;
}

[aria-label="Breadcrumb"] a[aria-current="page"] {
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumb/styles/Breadcrumb.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["[aria-label=\"Breadcrumb\"] ol {\n  --gap: 0.4rem;\n  list-style-type: none;\n  display: flex;\n  gap: var(--gap);\n}\n\n[aria-label=\"Breadcrumb\"] li {\n  --icon-size: 0.75rem;\n  display: flex;\n  align-items: center;\n  gap: var(--gap);\n}\n\n[aria-label=\"Breadcrumb\"] a {\n  text-decoration: none;\n  font-size: 1rem;\n  font-weight: inherit;\n}\n\n[aria-label=\"Breadcrumb\"] a[aria-current=\"page\"] {\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
