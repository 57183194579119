// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RevokeKey_revoke_key__SdaUU {
  padding: 0.5rem 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/key-details/components/RevokeKey/styles/RevokeKey.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB","sourcesContent":[".revoke_key {\n  padding: 0.5rem 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"revoke_key": `RevokeKey_revoke_key__SdaUU`
};
export default ___CSS_LOADER_EXPORT___;
