// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KeyDetailsTab_tab_container__l1ZLY {
  margin-right: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/key-details/components/KeyDetailsTab/styles/KeyDetailsTab.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB","sourcesContent":[".tab_container {\n  margin-right: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab_container": `KeyDetailsTab_tab_container__l1ZLY`
};
export default ___CSS_LOADER_EXPORT___;
