import { forwardRef, Ref } from "react";
import { Icon } from "../Icon";
import { IconType } from "../Icon/utils/types";
import styles from "./styles/SubmitButton.module.css";

interface SubmitButtonProps {
  label?: string;
  iconLeft?: string | null;
  iconRight?: string | null;
}

export const SubmitButton = forwardRef(
  (
    { label = "Submit", iconLeft = null, iconRight = null }: SubmitButtonProps,
    ref: Ref<HTMLButtonElement> | null
  ) => (
    <button
      type="submit"
      className={`primary button ${styles.submit_btn}`}
      ref={ref}
    >
      {iconLeft && <Icon name={iconLeft as IconType} />}
      <span>{label}</span>
      {iconRight && <Icon name={iconRight as IconType} />}
    </button>
  )
);
