// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dashboard_text_container__z2aMW {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  background-color: var(--backgroundSecondary);
  border-radius: 1rem;
  padding: 1.5rem 1.875rem;
}

.Dashboard_text_container__z2aMW h2 {
  font-size: 1.25rem;
  line-height: 1;
}

.Dashboard_text_container__z2aMW p {
  font-weight: 500;
  font-size: 0.875rem;
}

.Dashboard_text_container__z2aMW button {
  border: none;
  padding: 0.5rem 1rem;
}

.Dashboard_text_container__z2aMW button svg {
  --add-icon-size: 1rem;
  width: var(--add-icon-size);
  height: var(--add-icon-size);
}

.Dashboard_table_container__jZpwH {
  margin-top: 1.5rem;
  border-radius: 0.875rem;
  background-color: var(--backgroundSecondary);
  padding: 1rem 1.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/styles/Dashboard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,4CAA4C;EAC5C,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;EACrB,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,4CAA4C;EAC5C,qBAAqB;AACvB","sourcesContent":[".text_container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 0.625rem;\n  background-color: var(--backgroundSecondary);\n  border-radius: 1rem;\n  padding: 1.5rem 1.875rem;\n}\n\n.text_container h2 {\n  font-size: 1.25rem;\n  line-height: 1;\n}\n\n.text_container p {\n  font-weight: 500;\n  font-size: 0.875rem;\n}\n\n.text_container button {\n  border: none;\n  padding: 0.5rem 1rem;\n}\n\n.text_container button svg {\n  --add-icon-size: 1rem;\n  width: var(--add-icon-size);\n  height: var(--add-icon-size);\n}\n\n.table_container {\n  margin-top: 1.5rem;\n  border-radius: 0.875rem;\n  background-color: var(--backgroundSecondary);\n  padding: 1rem 1.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text_container": `Dashboard_text_container__z2aMW`,
	"table_container": `Dashboard_table_container__jZpwH`
};
export default ___CSS_LOADER_EXPORT___;
