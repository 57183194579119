import { ReactNode, useEffect, useRef } from "react";
import { noop } from "../../utils/general";
import { Icon } from "../Icon";
import "./styles/ErrorModal.module.css";

interface ErrorModalProps {
  message: ReactNode;
  onClose: typeof noop;
}

export const ErrorModal = ({ message, onClose }: ErrorModalProps) => {
  const errorModalRef = useRef<HTMLDialogElement>(null!);

  const handleManualModalClose = () => {
    errorModalRef.current.close();
    onClose();
  };

  useEffect(() => {
    errorModalRef.current.showModal();
  }, []);

  return (
    <dialog aria-labelledby="modal-title" onClose={onClose} ref={errorModalRef}>
      <button type="button" autoFocus onClick={handleManualModalClose}>
        <Icon name="Close" accessible_name="Close" hidden4Sr={false} />
      </button>
      <h2 id="modal-title">ERROR</h2>
      <p>{message}</p>
      <button
        type="button"
        onClick={handleManualModalClose}
        className="primary button"
      >
        Close
      </button>
    </dialog>
  );
};
